.App {
  text-align: center;
  align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mainCon {
  display: flex;
}
